import AuthService from '../services/auth.service';
import axios from 'axios';

export default {
    state: {
        token: localStorage.getItem('token') || ''
    },
    actions: {
        login({ commit }, user) {
            return AuthService.login(user).then(
                token => {
                    commit('loginSuccess', token)
                    return Promise.resolve(token);
                },
                error => {
                    commit('loginFailure');
                    return Promise.reject(error);
                }
            );
        },
        logout({ commit }) {
            commit('logout');
        },
        // register({ commit }, user) {
        //   return AuthService.register(user).then(
        //     response => {
        //       commit('registerSuccess');
        //       return Promise.resolve(response.data);
        //     },
        //     error => {
        //       commit('registerFailure');
        //       return Promise.reject(error);
        //     }
        //   );
        // }
    },
    mutations: {
        loginSuccess(state, token) {
            state.token = token;
        },
        loginFailure(state) {
            state.token = '';
        },
        logout(state) {
            localStorage.removeItem('token');
            delete axios.defaults.headers.common['Authorization'];
            state.token = '';
        },
        // registerSuccess(state) {
        //     state.status.loggedIn = false;
        // },
        // registerFailure(state) {
        //     state.status.loggedIn = false;
        // }
    },
    getters: {
        isAuthenticated: state => !!state.token,
    }

}

