export default {
    namespaced: true,
    state: {
        selectedCategory: null
    },
    actions: {
        select({ commit }, category) {
            commit('setCategory', category);
        },

        diselect({ commit }) {
            commit('unsetCategory');
        },
    },
    mutations: {
        setCategory(state, category) {
            state.selectedCategory = category;
        },

        unsetCategory(state) {
            state.selectedCategory = null;
        }
    },
    getters: {
        
    }

}

