import axios from 'axios';

class AuthService {
  login(user) {
    return axios
      .post('auth/token/login/', {
        email: user.email,
        password: user.password
      })
      .then(response => {
        if (response.data.auth_token) {
          localStorage.setItem('token', response.data.auth_token);
          axios.defaults.headers.common['Authorization'] = "Token " + response.data.auth_token
        }

        return response.data;
      });
  }

  activate(uid, token) {
    return axios
      .post('auth/users/activation/', {
        uid: uid,
        token: token,
      })
      .then(response => {
        return response.data;
      });
  }

  currentUser() {
    return axios
      .get('auth/users/me/')
      .then(response => {
        return response.data;
      });
  }

  setPassword(passwordData) {
    return axios
      .post('auth/users/set_password/', {
        new_password: passwordData.new_password,
        re_new_password: passwordData.re_new_password,
        current_password: passwordData.current_password
      })
      .then(response => {
        return response.data;
      });
  }

  resetPassword(email) {
    return axios
      .post('auth/users/reset_password/', {
        email: email,
      })
      .then(response => {
        return response.data;
      });
  }

  resetPasswordConfirm(uid, token, new_password) {
    return axios
      .post('auth/users/reset_password_confirm/', {
        uid: uid,
        token: token,
        new_password: new_password
      })
      .then(response => {
        return response.data;
      });
  }

  updateProfile(user) {
    return axios
      .patch('auth/users/me/', {
        first_name: user.first_name,
        last_name: user.last_name,
        email: user.email,
      })
      .then(response => {
        return response.data;
      });
  }

  logout() {
    return axios
      .post('auth/token/logout/')
      .then(response => {
        return response.data;
      });
  }

  register(user) {
    return axios
      .post('auth/users/', {
        first_name: user.first_name,
        last_name: user.last_name,
        email: user.email,
        password: user.password,
        terms_accepted: user.terms_accepted,
        profile_type: user.profile_type,
        employee_number: user.employee_number
      })
      .then(response => {
        return response.data;
      });
  }
}

export default new AuthService();