import _ from "lodash";

export default {
    namespaced: true,
    state: {
        selectedProducts: []
    },
    actions: {
        add({ commit }, product) {
            commit('addProduct', product);
        },

        remove({ commit }, product) {
            commit('removeProduct', product);
        },

        reset({ commit }) {
            commit('resetProducts');
        },
    },
    mutations: {
        addProduct(state, product) {
            state.selectedProducts.push({ id: product.id, category_id: product.category.id });
        },
        removeProduct(state, product) {
            _.remove(state.selectedProducts, (n) => {
                return n.id == product.id;
            });
        },
        resetProducts(state) {
            state.selectedProducts = [];
        },
    },
    getters: {

    }

}

