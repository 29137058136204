export default {
    namespaced: true,
    state: {
        selectedSubCategory: null
    },
    actions: {
        select({ commit }, subCategory) {
            commit('setSubCategory', subCategory);
        },

        diselect({ commit }) {
            commit('unsetSubCategory');
        },
    },
    mutations: {
        setSubCategory(state, subCategory) {
            state.selectedSubCategory = subCategory;
        },

        unsetSubCategory(state) {
            state.selectedSubCategory = null;
        }
    },
    getters: {
        
    }

}

