export default {
    namespaced: true,
    state: {
        selectedBrand: null,
        selectedBrands: []
    },
    actions: {
        select({ commit }, brand) {
            commit('setBrand', brand);
        },

        diselect({ commit }) {
            commit('unsetBrand');
        },

        selectBrands({ commit }, brands) {
            commit('setBrands', brands)
        },

        diselectBrands({ commit }) {
            commit('unsetBrands')
        }
    },
    mutations: {
        setBrands(state, brands) {
            state.selectedBrands = brands;
        },

        setBrand(state, brand) {
            state.selectedBrand = brand;
        },

        unsetBrand(state) {
            state.selectedBrand = null;
        },

        unsetBrands(state) {
            state.selectedBrands = [];
        }
    },
    getters: {
        
    }

}

