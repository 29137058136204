/* global $ */

import '@babel/polyfill'
import 'mutationobserver-shim'

import Vue from 'vue'
import axios from 'axios'

import './assets/styles/fontawesome-webfont.scss'
import 'bootstrap'
import './assets/styles/bootstrap.scss'
import './assets/styles/main.scss'

import './plugins/bootstrap-vue'
import './plugins/moment-vue'
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
import "./plugins/perfect-scrollbar"

import App from './App.vue'
import router from './router'
import store from './store'


// install rules
Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});

// Install components globally
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);


axios.defaults.baseURL = 'https://admin.bshappliancesdirect.co.uk';
const token = localStorage.getItem('token')
if (token) {
  axios.defaults.headers.common['Authorization'] = "Token " + token
}

axios.interceptors.response.use(function (response) {
  return response
}, function (error) {
  if (error.response.status === 403) {
    store.dispatch('logout');
    router.push('/login');
  }
  return Promise.reject(error)
})

Vue.config.productionTip = false;

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')

$(function () {
  $('[data-toggle="tooltip"]').tooltip()
})
