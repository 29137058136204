export default {
    namespaced: true,
    state: {
        selectedFilters: {}
    },
    actions: {
        add({ commit }, filters) {
            commit('setFilters', filters);
        },

        remove({ commit }) {
            commit('unsetFilters');
        },
    },
    mutations: {
        setFilters(state, filters) {
            state.selectedFilters = filters;
        },

        unsetFilters(state) {
            state.selectedFilters = {};
        }
    },
    getters: {
        
    }

}
