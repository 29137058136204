import Vuex from 'vuex';
import Vue from 'vue';
import createPersistedState from "vuex-persistedstate";
import auth from './auth.module';
import brand from './brand.module';
import category from './category.module';
import subCategory from './sub-category.module';
import product from './product.module';
import filters from "./filters.module";

// Load Vuex
Vue.use(Vuex);

// Create store
export default new Vuex.Store({
  modules: {
    auth,
    brand,
    category,
    subCategory,
    product,
    filters
  },
  plugins: [createPersistedState()]
});
